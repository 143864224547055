import Together from "together-ai";
let userAPIKey = "413c4e4379abbba86100f48dbf70be905f68aa30a1a75ce9bdc6a093ef952023"

export async function image_generation(prompt: string) {
    //let options: ConstructorParameters<typeof Together>[0] = {};
  
    const client = new Together({baseURL: "https://api.together.xyz/v1", apiKey: userAPIKey});
    //client.apiKey = userAPIKey;
    //client.baseURL = "https://api.together.xyz/v1"  

let response;
  try {
    response = await client.images.create({
      prompt,
      model: "black-forest-labs/FLUX.1-schnell",
      width: 256,
      height: 256,
      steps: 3,
      response_format: "base64",
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    return Response.json(
      { error: e.toString() },
      {
        status: 500,
      },
    );
  }
  return Response.json(response.data[0]);
}
