import React, { useState } from 'react';
import './Menu.css'; // Import CSS for styling
import { ClaudeApiCall } from './ApiCalls';

interface MenuProps {
    onImageGeneration: () => void; // Define the type for the prop
}

const Menu: React.FC<MenuProps> = ({ onImageGeneration }) => {
    const [isOpen, setIsOpen] = useState(false); // State to manage menu open/close

    const toggleMenu = () => {
        setIsOpen(!isOpen); // Toggle the menu state
    };

    const handleMenuClick = async () => {
        const contextArray = ["Context string 1", "Context string 2", "Context string 3"]; // Replace with your actual context strings
        const prompt = "Your question here"; // Replace with the actual question you want to send
        const response = await ClaudeApiCall(prompt, contextArray);
        console.log(response); // Handle the response as needed
    };

    return (
        <nav className="menu">
            <div className="hamburger" onClick={toggleMenu}>
                {/* Hamburger icon */}
                <div className={`bar ${isOpen ? 'open' : ''}`}></div>
                <div className={`bar ${isOpen ? 'open' : ''}`}></div>
                <div className={`bar ${isOpen ? 'open' : ''}`}></div>
            </div>
            <ul className={`menu-list ${isOpen ? 'open' : ''}`}>
                <li className="menu-item" onClick={handleMenuClick}>Claude Chat</li> {/* First option */}
                <li className="menu-item" onClick={onImageGeneration}>Image Generation</li> {/* Call the function on click */}
            </ul>
        </nav>
    );
};

export default Menu;
