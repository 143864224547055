import { useEffect, useState } from 'react';
import { startVoiceRecording, stopVoiceRecording } from './Audio';

function speakMessage(text: string) {
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = 'en-US'; // Set the language to English
    window.speechSynthesis.speak(utterance); // Speak the message
}
async function send_message(type: string, text: string) {
    // Sending the message to the FastAPI endpoint
    try {
        const url = `//${window.location.hostname}:8443/api/messages`;
        console.log(url);
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text: text, type: type }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log('Message sent successfully:', data);

        // Call the TTS function after sending the message
        speakMessage(text);
    } catch (error) {
        console.error('Error sending message:', error);
    }
}
async function send_draw_message(text: string) {
    await send_message('draw', text);
}
async function send_command_message(text: string) {
    await send_message('draw', text);
}

function Message() {
    const [message, setMessage] = useState('');

    useEffect(() => {
        const recordButton = document.getElementById('recordButton');
        const stopButton = document.getElementById('stopButton');

        if (recordButton) {
            recordButton.addEventListener('click', startVoiceRecording);
        }
        if (stopButton) {
            stopButton.addEventListener('click', () => {
                stopVoiceRecording((recognizedText) => {
                    setMessage(recognizedText); // Set the recognized text to the message state
                });
            });
        }

        return () => {
            if (recordButton) {
                recordButton.removeEventListener('click', startVoiceRecording);
            }
            if (stopButton) {
                stopButton.removeEventListener('click', () => {
                    stopVoiceRecording((recognizedText) => {
                        setMessage(recognizedText); // Set the recognized text to the message state
                    });
                });
            }
        };
    }, []);


    return (
        <div>
            <h1 className="text-2xl font-bold">Message Sender</h1>
            <input 
                type="text"
                className="border border-gray-300 rounded-md p-2"
                value={message} 
                onChange={(e) => setMessage(e.target.value)} 
                placeholder="Type your message here" 
            />
            <div>
                <button className="bg-blue-500 text-white p-2" onClick={async () => {
                await send_command_message(message);
                }}>Send Command</button>
                <button className="bg-blue-500 text-white p-2" onClick={async () => {
                    await send_draw_message(message);
                }}>Send Draw</button>
            </div>
            <div>
                <button id="recordButton" className="bg-blue-500 text-white p-2">Record Voice</button>
                <button id="stopButton" className="bg-blue-500 text-white p-2">Stop Recording</button>
            </div>
        </div>
    );
}

export default Message;