let recognition;
let recognizedText = '';

export function startVoiceRecording() {
    recognizedText = '';
    recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
    recognition.lang = 'en-US';
    recognition.interimResults = false;

    recognition.onresult = function(event) {
        recognizedText = event.results[0][0].transcript;
        console.log('You said: ', recognizedText);
    };

    recognition.start();
}

export function stopVoiceRecording(callback) {
    if (recognition) {
        recognition.stop();
        recognition.onend = () => {
            callback(recognizedText);
        };
    }
}