import { useState } from 'react';
import './App.css'
import Message from './Components/Message';
import LoginForm from './Components/LoginForm';
import Menu from './Components/Menu';
import { image_generation } from './Components/Image';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status

  const handleLoginSuccess = () => {
      setIsLoggedIn(true); // Update state on successful login
  };

  const handleImageGeneration = async () => {
      const prompt = "Your prompt here"; // Replace with actual prompt input
      await image_generation(prompt); // Call the image_generation function
  };

  return (
    <div>
        <div className="absolute top-0 left-0 right-0 bg-black bg-opacity-50">
            <Menu onImageGeneration={handleImageGeneration} />
        </div>
        <div className="flex justify-center items-center h-screen">
            {isLoggedIn ? (
              <LoginForm onLoginSuccess={handleLoginSuccess} /> // Pass the success handler
            ) : (
              <Message />
            )}
        </div>
    </div>
  );
};

export default App;
