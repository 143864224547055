let apiKey = "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"

export async function ClaudeApiCall(apiKey, contextArray, prompt) {
    const response = await fetch("https://api.anthropic.com/v1/messages", {
        method: "POST",
        headers: {
            "x-api-key": apiKey,
            "anthropic-version": "2023-06-01",
            "content-type": "application/json",
            "anthropic-dangerous-direct-browser-access": "true",
        },
        body: JSON.stringify({
            model: "claude-3-haiku-20240307",
            max_tokens: 1024,
            messages: [
                {
                    role: "user",
                    content: [
                        ...contextArray.map(text => ({ type: "text", text })),
                        { type: "text", text: prompt },
                    ],
                },
            ],
        }),
    });
    const data = await response.json();
    // Handle the response
    return 'Claude: ' + data.content[0].text;
}